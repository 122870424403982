import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Navbar from "@components/navbar"
import Footer from "@components/footer"
import Announcement from "@components/announcement"
import { FaCheck } from "react-icons/fa"
import { LazyLoadImage } from "react-lazy-load-image-component"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <div className="page-layout">
      <section className="intro intro--no-waves">
        <div className="container">
          <Navbar />
          <div className="container-blog container text-left">
            <h1 className="labs-title">
              Our Services
              <span className="labs-blue">.</span>
            </h1>
          </div>
        </div>
      </section>
      <section className="wave-wrapper mt-5 pt-5">
        <section className="dark-bg page-wrapper">
          <div className="container-blog-content container">
            <div className="dark-bg page-container">
              <div className="services-page">
                <div className="row services-row">
                  <div className="col-12 col-xl-6 col-lg-5">
                    <figure className="image-wrapper">
                      <LazyLoadImage
                        src="/images/services/development.jpg"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-7 text-left card-container">
                    <div className="card-wrapper card-wrapper--right">
                      <h3 className="labs-blue">Helping Teams Be Successful</h3>
                      <h2>Consulting</h2>
                      <p>
                        Our goal is to share our expertise and domain-knowledge
                        with your team, tackling tough issues right by your
                        side, collaborating with your team on many levels such
                        as:
                        <br />
                      </p>
                      <ul>
                        <li>
                          <span>
                            <FaCheck />
                          </span>
                          Providing technical guidance & architectural reviews
                        </li>
                        <li>
                          <span>
                            <FaCheck />
                          </span>
                          Advising best practices
                        </li>
                        <li>
                          <span>
                            <FaCheck />
                          </span>
                          Solving design decisions
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row services-row">
                  <div className="col-12 col-xl-6 col-lg-5 d-lg-none">
                    <figure className="image-wrapper">
                      <LazyLoadImage
                        src="/images/services/consulting.jpg"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-7 text-left card-container">
                    <div className="card-wrapper card-wrapper--left">
                      <h3 className="labs-blue">Build & Scale Faster</h3>
                      <h2>Team Augmentation</h2>
                      <p>
                        With team augmentation, Labs42 members become an
                        extension of your team. Working directly with you on a
                        daily basis to help take your project to the next-level.
                        We aim to:
                        <br />
                      </p>
                      <ul>
                        <li>
                          <span>
                            <FaCheck />
                          </span>
                          Mentor team members
                        </li>
                        <li>
                          <span>
                            <FaCheck />
                          </span>
                          Address security & performance concerns
                        </li>
                        <li>
                          <span>
                            <FaCheck />
                          </span>
                          Perform in-depth code reviews
                        </li>
                        <li>
                          <span>
                            <FaCheck />
                          </span>
                          Assist in Engineering & Development
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-5 d-none d-lg-block">
                    <figure className="image-wrapper">
                      <LazyLoadImage
                        src="/images/services/consulting.jpg"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
                <div className="row services-row">
                  <div className="col-12 col-xl-6 col-lg-5">
                    <figure className="image-wrapper">
                      <LazyLoadImage
                        src="/images/services/workshops.jpg"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-7 text-left card-container">
                    <div className="card-wrapper card-wrapper--right">
                      <h3 className="labs-blue">Learn, Evolve, Grow</h3>
                      <h2>Corporate Workshops & Trainings</h2>
                      <p>
                        Get your team up to speed with our experts in modern
                        technologies. Our goal is to change the way we approach
                        knowledge, learning, and education through hands-on
                        training tailored to your needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row services-row">
                  <div className="col-12 d-lg-none">
                    <figure className="image-wrapper">
                      <LazyLoadImage
                        src="/images/services/corporate.jpg"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-7 text-left card-container">
                    <div className="card-wrapper card-wrapper--left">
                      <h3 className="labs-blue">Setting up for Success</h3>
                      <h2>Architectural Planning</h2>
                      <p>
                        Let's plan the next phases of your application, setting
                        up the groundwork for growth, scale, and future success.
                        <br /> <br />
                        With our core-team members with expertise in different
                        industries, open-source maintainers & contributors, you
                        can be confident that your project is in good hands.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-5 d-none d-lg-block">
                    <figure className="image-wrapper">
                      <LazyLoadImage
                        src="/images/services/corporate.jpg"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className="dark-bg"></div>
      <Footer />
    </div>
  </Layout>
)

export default ServicesPage
